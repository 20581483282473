/* eslint-disable react/jsx-no-target-blank */
import {useEffect, useState} from 'react'
import {KTIcon} from '../../../helpers'
import api from '../../../../app/api'

const SidebarFooter = () => {
  const [datePart, setDatePart] = useState('')
  useEffect(() => {
    getServerTime()
  }, [])

  const getServerTime = async () => {
    try {
      const resp = await api.systemParameters.getServerTime()
      setDatePart(resp.data.toString())
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      {/* <a
        href={process.env.REACT_APP_PREVIEW_DOCS_URL}
        target='_blank'
        className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        data-bs-toggle='tooltip'
        data-bs-trigger='hover'
        data-bs-dismiss-='click'
        title='Metronic Docs & Components'
      > */}
      <div className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'>
        <span className='btn-label'>{datePart}</span>
        {/* <KTIcon iconName='document' className='btn-icon fs-2 m-0' /> */}
      </div>
      {/* </a> */}
    </div>
  )
}

export {SidebarFooter}
