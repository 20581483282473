import axios from "axios"
import { ParticipantsPayload } from "../models/response/ParticipationsPayload"
import coreClient from "../coreClient"

export function getParticipants(pageNumber?: number, pageSize?: number) {
    const path = pageNumber ? `/Participations/Participants?PageNumber=${pageNumber}&PageSize=${pageSize}` : `/Participations/Participants`
    return coreClient.get<ParticipantsPayload[]>(path, {
      headers: {accept: 'application/json'},
    })
  }

  export function getRewardedVideosPerUser(scroreMultiplier: number, pageNumber?: number, pageSize?: number) {
    const path = pageNumber ? `/Participations/Participants?PageNumber=${pageNumber}&PageSize=${pageSize}&ScoreMuliplier=${scroreMultiplier}` : `/Participations/Participants?ScoreMuliplier=${scroreMultiplier}`
    return coreClient.get<ParticipantsPayload[]>(path, {
      headers: {accept: 'application/json'},
    })
  }