import {formatNumberWithCommas} from '../../../utilitites/formatNumber'

type Props = {
  value: string | number
  text: string
  color?: string
}

export default function TopBoxes({value, text, color}: Props) {
  const formattedNumber = formatNumberWithCommas(value)
  return (
    <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
      <div className='card card-flush h-xl-100' style={{backgroundColor: color}}>
        <div className='card-header py-7'>
          <div className='m-0'>
            <div className='d-flex align-items-center mb-2'>
              <span className='fs-lg-1 fw-bold text-gray-800 me-2 lh-1 ls-n2'>
                {formattedNumber}
              </span>
            </div>

            <span className='fs-6 fw-semibold text-gray-500'>{text}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
