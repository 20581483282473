/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {TournamentsPayload} from '../../../../../../api/models/response/TournamentsPayload'
import { ChampionshipPayload } from '../../../../../../api/models/response/ChampionshipPayload'
import { UsersPayload } from '../../../../../../api/models/response/UsersPayload'

type Props = {
  item: UsersPayload
}

const EmailCell: FC<Props> = ({item}) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span> {item?.email}</span>
      </div>
    </div>
  )
}
export {EmailCell}
