import {FC, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import Championship from './championship'
import {DtPicker} from 'react-calendar-datetime-picker'
import 'react-calendar-datetime-picker/dist/style.css'
import './championship.scss'

const ChampionshipWrapper: FC = () => {
  const [date, setDate] = useState({})

  const handleChange = (selectedDate) => {
    // Check if selectedDate is an object with year, month, and day properties
    if (
      selectedDate &&
      selectedDate.year !== undefined &&
      selectedDate.month !== undefined &&
      selectedDate.day !== undefined
    ) {
      // Construct a Date object from the properties
      const date = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day) // month is zero-based

      const newdate = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day) // month is zero-based

      // Add one day to the selected date
      newdate.setDate(newdate.getDate() + 1)

      const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${date.getFullYear()}`

      // Format the date as dd/mm/yyyy
      const newFormattedDate = `${newdate.getDate().toString().padStart(2, '0')}/${(
        newdate.getMonth() + 1
      )
        .toString()
        .padStart(2, '0')}/${newdate.getFullYear()}`
      const startDate = formattedDate + ' 00:10:00'
      const endDate = newFormattedDate + ' 00:10:00'
      console.log('end', startDate, endDate)
      setDate({startDate, endDate})
      // Use the formatted date
      console.log(newFormattedDate) // You can replace console.log with your logic to use the formatted date
    } else {
      console.error('Invalid date format')
    }
  }

  function formatDate(input) {
    let startDate = ''
    let endDate = ''
    if (input) {
      const {year, month, day} = input
      const formattedDate = `${padZero(day)}/${padZero(month)}/${year} `

      const formattedDateNew = `${padZero(day + 1)}/${padZero(month)}/${year} `
      startDate = formattedDate + ' 00:10:00'
      endDate = formattedDateNew + ' 00:10:00'
      console.log('new datee', formattedDateNew)
    }
    return {startDate, endDate}
  }

  function padZero(num) {
    return num.toString().padStart(2, '0')
  }
  const [search, setSearch] = useState<string>()

  return (
    <div style={{paddingTop: '-120px !important'}}>
      <div className='d-flex justify-content-between mb-2'>
        <div className='d-flex align-items-center gap-5'>
          <label className='fs-6 fw-bold mb-5 text-gray-700 '>Search by UserID:</label>
          <input
            type='text'
            className='form-control form-control-sm form-control-solid w-200px ps-5 mb-5'
            name='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder='Search'
          />
        </div>
        <div className='d-flex align-items-center gap-5'>
          <label className='fs-6 fw-bold text-gray-700'>Please, select date</label>
          <span className=''>
            <DtPicker onChange={handleChange} />
          </span>
        </div>
      </div>
      <PageTitle breadcrumbs={[]}>Leaderboard</PageTitle>
      <div style={{paddingTop: '-30px'}}>
        <Championship date={date} searchData={search} />
      </div>
    </div>
  )
}

export {ChampionshipWrapper}
