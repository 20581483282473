import axios from 'axios'
import {GameGenericType} from '../models/response/GamePayload'
import client from '../client'
import coreClient from '../coreClient'

export function get() {
  return coreClient.get<GameGenericType[]>(`/GameSources`, {
    headers: {accept: 'application/json'},
  })
}
