import styles from './championshipRow.module.scss'
import earth from '../../assets/Earth.png'
import defaultPic from '../../assets/DefAvatar.png'
import rp from '../../assets/RP.png'
import coin from '../../assets/Coin.png'

type Props = {
  position: number
  nickname: string
  userId: string
  points: string
  amount: string
  country: string
  tournamentName: string
  img: string
  isSpecial?: boolean
}
const ChampionshipRow = ({
  position,
  nickname,
  userId,
  amount,
  points,
  country,
  tournamentName,
  img,
  isSpecial,
}: Props) => {
  let style = {
    backgroundColor: isSpecial ? 'orange' : '',
  }
  return (
    <div className={styles.userRowSection} style={style}>
      <span className={styles.ranking}>
        <b># {position}</b>
      </span>

      <span className={styles.flag}>
        {country ? (
          <img className={styles.flag} alt='code2' src={`/Flags/${country}.png`} />
        ) : (
          <img className={styles.flag} alt='' src={earth} />
        )}
      </span>

      <span className={styles.img}>
        {' '}
        <img
          className={styles.img}
          alt='prof pic'
          src={img ? `https://api.mesl.eu/api/v1/Files/${img}/Image` : defaultPic}
        ></img>
      </span>
      <span className={styles.name}>
        {' '}
        <b>{nickname}</b>
      </span>
      <span className={styles.userId}>
        <b>{`[${userId}]`}</b>
      </span>
      <span className={styles.amount}>
        <img className={styles.img} alt='' src={rp} />
        <b>{amount}</b>
      </span>
      <span className={styles.score}>
        <img className={styles.flag} alt='' src={coin} />
        <b>{points}</b>
      </span>
    </div>
  )
}

export default ChampionshipRow
