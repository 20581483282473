/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {TournamentsPayload} from '../../../../../../api/models/response/TournamentsPayload'
import { ChampionshipPayload } from '../../../../../../api/models/response/ChampionshipPayload'
import { UsersPayload } from '../../../../../../api/models/response/UsersPayload'

type Props = {
  item: UsersPayload
}

const CampaignCell: FC<Props> = ({item}) => {
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='d-flex flex-column'>
        {/* <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {company.regNumber}
      </a> */}
        <span> {item?.campaign}</span>
      </div>
    </div>
  )
}
export {CampaignCell}
