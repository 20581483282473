import axios, {AxiosResponse} from 'axios'
import {StreamingResponse} from '../models/response/StreamingPayload'
import {StreamingPayload} from '../models/payload/StreamingPayload'
import streamingClient from '../streamingClient'


export function postFiles(payload: any) {
  return streamingClient.post<StreamingPayload>(`/Files`, payload)
}

export function getFilesById(key: string) {
  let path = `/Files/${key}`
  return streamingClient.get<StreamingResponse>(path)
}

export function updateFiles(key: string, payload: any) {
  return streamingClient.put<StreamingResponse>(
    `/Files/${key}`,
    payload
  )
}

export function deleteFileTag(key: string, tagId: number): Promise<AxiosResponse<any>> {
  return streamingClient.delete(`/Files/${key}/Tags/${tagId}`)
}

export function postFileTags(key: string, payload: any) {
  return streamingClient.post<StreamingPayload>(
    `/Files/${key}/Tags`,
    payload
  )
}
