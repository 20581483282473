import axios from 'axios'
import {SystemParametersPayload} from '../models/payload/SystemParametersPayload'
import {SystemParametersResponse} from '../models/response/SystemParameters'
import {ID} from '../../../_metronic/helpers'
import client from '../client'
import coreClient from '../coreClient'

export function getSystemParameters(pageNumber?: number, pageSize?: number, platformId?: number) {
  let path = `/SystemParameters`
  if (pageNumber != null || pageSize != null || platformId != null) {
    path += `?`
  }
  if (pageNumber != null) {
    path += `PageNumber=${pageNumber}&`
  }
  if (pageSize != null) {
    path += `PageSize=${pageSize}&`
  }
  if (platformId != null) {
    path += `&PlatformId=${platformId}&`
  }
  return coreClient.get<SystemParametersPayload>(path)
}

export function getSystemParameterById(id: number) {
  return coreClient.get<SystemParametersPayload>(`/SystemParameters/${id}`, {
    headers: {accept: 'application/json'},
  })
}

export function postSystemParameters(id: number, payload: any) {
  return coreClient.post<SystemParametersResponse>(`/SystemParameters`, payload)
}

export function updateSystemParameters(id: number, payload: any) {
  return coreClient.put<SystemParametersResponse>(`/SystemParameters/${id}`, payload)
}

export function deleteSystemParameter(userId: ID): Promise<void> {
  return client.delete(`/SystemParameters/${userId}`).then(() => {})
}

export function getServerTime() {
  return coreClient.get<SystemParametersPayload>(`https://api.mesl.eu/api/v1/SystemParameters/Time`)
}
