import axios from 'axios';

export const CORE_API_URL = process.env.REACT_APP_CORE_URL ? process.env.REACT_APP_CORE_URL + '/api/v1' : `https://test-api-core.mesl.eu/api/v1`;

const coreClient = axios.create({
    // withCredentials: true,
    // baseURL: 'https://test-api.mesl.eu/api/v1.0',
    baseURL: CORE_API_URL
});

console.log('env variables', process.env.REACT_APP_API_KEY)
export default coreClient;

