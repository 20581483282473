import axios from 'axios'
import {encryptString, generateRandomString} from './crypto'

export const BASE_API_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL + '/api/v1'
  : 'https://test-api.mesl.eu/api/v1.0'

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT
const ENTITY = process.env.REACT_APP_Entity!
export const isCryptoEntity = ENTITY === 'crypto'
console.log('crypto', isCryptoEntity) 

const client = axios.create({
  // withCredentials: true,
  // baseURL: 'https://test-api.mesl.eu/api/v1.0',
  baseURL: BASE_API_URL,
})

console.log('env variables', process.env.REACT_APP_API_KEY)
export default client

// Request interceptor
client.interceptors.request.use(
  (config) => {
    const key = process.env.REACT_APP_ENCRYPTION_KEY // Replace with your key
    const iv = process.env.REACT_APP_ENCRYPTION_IV // Replace with your IV
    const plainText = generateRandomString()
    const encryptedText = encryptString(plainText, key, iv)
    if (encryptedText) {
      config.headers['X-Security-Token'] = encryptedText
    }
    return config
  },
  (error) => {
    // Handle request error
    return Promise.reject(error)
  }
)
