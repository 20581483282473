import CryptoJS from 'crypto-js';

export const encryptString = (plainText, key, iv) => {
    // Parse the key and IV from UTF-8 to WordArray
    const parsedKey = CryptoJS.enc.Utf8.parse(key);
    const parsedIv = CryptoJS.enc.Utf8.parse(iv);
    // Encrypt the plaintext using AES
    const encrypted = CryptoJS.AES.encrypt(plainText, parsedKey, { iv: parsedIv });
    // Convert the encrypted data to Base64 string
    return encrypted.toString();
};


export function generateRandomString(length = 4) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  