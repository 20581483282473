import axios from 'axios'
import { UsersPayload } from '../models/response/UsersPayload'
import identityClient from '../identityClient'

export function getById(userId: string) {
  return identityClient.get(`/Users/${userId}`, {
    headers: {accept: 'application/json'},
  })
}


export function getUsers(pageNumber: number, pageSize: number, start?: any, end?: any) {
  const path = start ? `/Users?Page=${pageNumber}&PerPage=${pageSize}&CreateDateStart=${start}&CreateDateEnd=${end}` : `/Users?Page=${pageNumber}&PerPage=${pageSize}`
  return identityClient.get<UsersPayload[]>(path, {
    headers: {accept: 'application/json'},
  })
}