import axios, { AxiosResponse } from "axios";
import { FilesPayload } from "../models/payload/FilesPayload";
import client from "../client";
import filesServiceClient from "../filesServiceClient";

export function post(payload: FilesPayload): Promise<AxiosResponse<any>> {
    return filesServiceClient.post<any>(`/Files`, payload)
  }

  export function get(key: string) {
    return filesServiceClient.get<FilesPayload>(`/Files/${key}`, {
      headers: {accept: 'application/json'},
    })
  }