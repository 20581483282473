/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {TournamentsPayload} from '../../../../../../api/models/response/TournamentsPayload'
import {ChampionshipPayload} from '../../../../../../api/models/response/ChampionshipPayload'
import {UsersPayload} from '../../../../../../api/models/response/UsersPayload'
import {ParticipantsPayload} from '../../../../../../api/models/response/ParticipationsPayload'
import {formatNumberWithCommas} from '../../../../../../utilitites/formatNumber'

type Props = {
  item: ParticipantsPayload
}

const VideosCell: FC<Props> = ({item}) => {
  const formattedNumber = formatNumberWithCommas(item?.amount)

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <span> {formattedNumber}</span>
      </div>
    </div>
  )
}
export {VideosCell}
