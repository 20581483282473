import axios from 'axios'
import {PrizeBreakdown} from '../models/response/PrizeBreakdown'
import coreClient from '../coreClient'

export function get() {
  return coreClient.get<PrizeBreakdown[]>(
    `/PrizeBreakDowns?PageNumber=1&PageSize=100`,
    {
      headers: {accept: 'application/json'},
    }
  )
}
