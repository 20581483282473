import styles from './topBanner.module.scss'
import {useEffect, useState} from 'react'
import rp from '../../assets/rp.jpg'
import me from '../../assets/me.png'
import championship from '../../assets/championship.png'
import defaultPic from '../../assets/DefAvatar.png'
import coin from '../../assets/Coin.png'
import api from '../../../../api'
import User from '../user/user'
import {getFormattedDate} from '../../../../utilitites/getCurrentDate'

const MINUTE_MS = 60000

const TopBanner = ({date}) => {
  const [users, setUsers] = useState<any>([])
  const [datePart, setDatePart] = useState('')
  useEffect(() => {
    getServerTime()
  }, [])

  useEffect(() => {
    if (datePart) {
      fetchData()
    }
    // const interval = setInterval(() => {
    //   setUsers([])
    //   fetchData()
    // }, MINUTE_MS)
    // return () => clearInterval(interval)
  }, [date, datePart])

  const getServerTime = async () => {
    try {
      const resp = await api.systemParameters.getServerTime()
      const dateTimeString = resp.data.toString()
      setDatePart(dateTimeString?.split(' ')[0])
    } catch (error) {
      console.log(error)
    }
  }
  const isDataPresent = (data: any): boolean => !!data?.trim?.()

  const fetchData = async () => {
    let startDate = datePart + ' 00:10:00'
    let endDate = datePart + ' 23:59:59'
    if (isDataPresent(date?.startDate)) {
      startDate = date?.startDate
      endDate = date?.endDate
    }
    try {
      const response = await api.championship.getChampionship(startDate, endDate)
      setUsers([...response.data?.items])
    } catch (error) {
      console.log(error)
    }
  }

  const Children = ({name, points}: any) => {
    return (
      <>
        <p>
          <b>{name}</b>
        </p>
        <p>
          <b>
            {' '}
            <img src={coin} style={{width: '10%'}} /> {points}
          </b>
        </p>
      </>
    )
  }
  return (
    <section className={styles.topBannerSection}>
      {/* {users.length ? ( */}
      <img src={rp} style={{width: '50%', objectFit: 'cover', height: '100%'}} />
      <img src={me} style={{width: '50%', objectFit: 'cover', height: '100%'}} />

      <div className={styles.banner}>
        <div>
          <div>
            <img src={championship} className={styles.championship} />
          </div>

          {/* <img
            src={trophy}
            style={{
              paddingTop: "20px",
              backgroundColor: "white",
              width: "30%",
              objectFit: "contain",
              position: "absolute",
              // width: '50px',
              height: "80px",
              // backgroundColor: "blueviolet",
              left: "35%",
              top: "36%",
              zIndex: "1",
            }}
          /> */}
        </div>
        <div
          className={styles.users}
          style={{
            position: 'absolute',
            width: '30%',
            height: '200px',
            left: '35%',
            top: '30%',
            zIndex: '1',
          }}
        >
          {users[1] && (
            <User
              color='gray'
              width='50px'
              height='50px'
              style={false}
              shadow={false}
              rank={2}
              img={
                users[1]?.avatar2D
                  ? `https://api.mesl.eu/api/v1/Files/${users[1]?.avatar2D}/Image`
                  : defaultPic
              }
            >
              <Children name={users[1]?.nickname} points={users[1]?.points} />
            </User>
          )}
          {users[0] && (
            <User
              color='#FFD700'
              width='80px'
              height='80px'
              rank={1}
              img={
                users[0]?.avatar2D
                  ? `https://api.mesl.eu/api/v1/Files/${users[0]?.avatar2D}/Image`
                  : defaultPic
              }
            >
              <Children name={users[0]?.nickname} points={users[0]?.points} />
            </User>
          )}
          {users[2] && (
            <User
              color='orange'
              width='50px'
              height='50px'
              style={false}
              rank={3}
              img={
                users[2]?.avatar2D
                  ? `https://api.mesl.eu/api/v1/Files/${users[2]?.avatar2D}/Image.jpg`
                  : defaultPic
              }
            >
              <Children name={users[2]?.nickname} points={users[2]?.points} />
            </User>
          )}
        </div>
      </div>
    </section>
  )
}

export default TopBanner
