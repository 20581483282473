// @ts-nocheck
import {Column} from 'react-table'
import UserInfoCell from './UserInfoCell'
import {AdGroupCell} from './AdGroupCell'
import {UserCustomHeader} from './UserCustomHeader'
import {CampaignCell} from './CampaignCell'
import {EmailCell} from './EmailCell'

import {User} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Username' className='min-w-125px' />,
    id: 'username',
    Cell: ({...props}) => <UserInfoCell item={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Email' className='min-w-125px' />,
    id: 'email',
    Cell: ({...props}) => <EmailCell item={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='AdGroup' className='min-w-125px' />
    ),
    id: 'adGroup',
    Cell: ({...props}) => <AdGroupCell item={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Campaign' className='min-w-125px' />
    ),
    id: 'campaign',
    Cell: ({...props}) => <CampaignCell item={props.data[props.row.index]} />,
  },
]

export {usersColumns}
