import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'

import {usersColumns} from './columns/_columns'

import {KTCardBody} from '../../../../../../_metronic/helpers'
import {PaginatedItems} from '../../../../../shared/panination'
import api from '../../../../../api'
import {ParticipantsPayload} from '../../../../../api/models/response/ParticipationsPayload'
import { ENVIRONMENT } from '../../../../../api/client'

type Props = {
  videosData: ParticipantsPayload[]
  rewardedVideosCount: number
}

const RewardedVideosTable = ({videosData, rewardedVideosCount}: Props) => {
  // const users = useQueryResponseData()
  // const pagination = useQueryPaginationData()
  // const isLoading = useQueryResponseLoading()
  // const data = useMemo(() => users, [users])
  const columns = useMemo(() => usersColumns, [])
  const [data, setRewardedVideos] = useState<any>([])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const [totalCount, setTotalCount] = useState<number>()
  const [pageNumber, setPageNumber] = useState<number>(0)
  const pageSize = ENVIRONMENT === 'prod' ? 10 : 2;
  const scoreMultiplier = 1

  useEffect(() => {
    setRewardedVideos(videosData)
    console.log('tot', totalCount)
    setTotalCount(rewardedVideosCount)
  }, [videosData, rewardedVideosCount])

  useEffect(() => {
    if (pageNumber!==0) {
      getRewardedVideos(pageNumber, pageSize)
    }
  }, [pageNumber])

  const getRewardedVideos = async (pageNum: number, size: number) => {
    try {
      const response = await api.participations.getRewardedVideosPerUser(
        scoreMultiplier,
        pageNum,
        size
      )
      if (response.status == 200 || response.status == 201) {
        setRewardedVideos(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  //  const {updateState} = useQueryRequest()
  // const updatePage = (page: number | undefined | null) => {
  //   if (!page || isLoading || pagination.page === page) {
  //     return
  //   }
  // }

  const handlePageNumber = (num) => {
    const pageNumber = num
    setPageNumber(pageNumber)
    // updateState({PageNumber: num, PageSize: 10})
  }

  // useEffect(() => {
  //   if (pagination) {
  //     const parsedObject = JSON.parse(pagination)
  //     const tempTotalCount = parsedObject.totalCount
  //     const tempPageNumber = parsedObject.currentPage
  //     setTotalCount(tempTotalCount)
  //     setPageNumber(tempPageNumber)
  //   }
  // }, [pagination])

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <div className='border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Rewarded Videos</span>
          </h3>
        </div>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<any>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {totalCount && totalCount !== 0 ? (
        <PaginatedItems
          itemsPerPage={pageSize}
          numberOfItems={totalCount}
          handleClick={handlePageNumber}
          pageNumber={pageNumber}
        />
      ) : (
        null
      )}
      {/* <UsersListPagination /> */}
      {/* {isLoading && <UsersListLoading />} */}
    </KTCardBody>
  )
}

export {RewardedVideosTable}
