/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTCard, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import {FilesResponse} from '../../api/models/response/FilesResponse'
import TopBoxes from './components/topBoxes'
import {ChampionshipTable} from './components/championshipTable/table/ChampionshipTable'
import {SignUpTable} from './components/signUpTable/table/SignUpTable'
import {ParticipationsTable} from './components/participationsTable/table/ParticipationsTable'
import {RewardedVideosTable} from './components/rewardedVideosTable/table/RewardedVideosTable'
import {ChampionshipPayload} from '../../api/models/response/ChampionshipPayload'
import {ParticipantsPayload} from '../../api/models/response/ParticipationsPayload'
import {UsersPayload} from '../../api/models/response/UsersPayload'
import { ENVIRONMENT } from '../../api/client'
import api from '../../api'



const DashboardPage: FC = () => {
  // //winner
  // const [winner, setWinner] = useState<any>({})
  // const [winnerUser, setWinnerUser] = useState<any>({})

  // //Winner image
  // const [iconKey, setIconKey] = useState<FilesResponse>()
  // const [imgSrc, setImgSrc] = useState<string>()

  // useEffect(() => {
  //   getIcons(winner?.avatar2D)
  // }, [winner])

  // const getIcons = async (key: string) => {
  //   try {
  //     const resp = await api.files.get(key)
  //     if (resp.data.fileData) {
  //       setIconKey(resp.data)
  //     }
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
  // useEffect(() => {
  //   setImgSrc(`data:image/${iconKey?.extension};base64, ${iconKey?.fileData}`)
  // }, [iconKey?.fileData])

  // useEffect(() => {
  //   getWinner()
  // }, [])
  // useEffect(() => {
  //   getUserById(winner?.userId)
  // }, [winner])

  // const getWinner = async () => {
  //   try {
  //     const response = await api.championship.get()
  //     if (response.status == 200) {
  //       setWinner(response.data)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  // const getUserById = async (id: string) => {
  //   try {
  //     const response = await api.users.getById(id)
  //     if (response.status == 200) {
  //       setWinnerUser(response.data)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const [totalSignUps, setTotalSignUps] = useState<number>(1)
  const [signupsData, setSignupsData] = useState<UsersPayload[]>([])

  const [totalWinners, setTotalWinners] = useState<number>(0)
  const [winnersData, setWinnersData] = useState<ChampionshipPayload[]>([])

  const [totalRewardedVideos, setTotalRewardedVideos] = useState<number>(0)
  const [rewardedVideosCount, setRewardedVideosCount] = useState<number>(0)

  const [videosData, setVideosData] = useState<ParticipantsPayload[]>([])

  const [totalParticipations, setTotalParticipations] = useState<number>(0)
  const [participationsCount, setParticipationsCount] = useState<number>(0)
  const [participationsData, setParticipationsData] = useState<ParticipantsPayload[]>([])

  const defaultPageNum = 1
  const defaultPageSize = ENVIRONMENT === 'prod' ? 10 : 2
  const scoreMultiplier = 1

  useEffect(() => {
    getFormattedDate()

    getSignUps(defaultPageNum, defaultPageSize)
    getWinners(defaultPageNum, defaultPageSize)
    getParticipations(defaultPageNum, defaultPageSize)
    getRewardedVideos(scoreMultiplier, defaultPageNum, defaultPageSize)
  }, [])

  const [formattedDate, setFormattedDate] = useState('')

  const getFormattedDate = () => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
    const day = currentDate.getDate().toString().padStart(2, '0')
    const formattedDate = `${year}-${month}-${day}`
    setFormattedDate(formattedDate)
  }

  const getSignUps = async (pageNum: number, size: number) => {
    try {
      console.log('date', formattedDate)
      const startDate = formattedDate + ' 00:00:00'
      const endDate = formattedDate + ' 23:59:59'
      const response = await api.users.getUsers(pageNum, size, startDate, endDate)
      if (response.status == 200 || response.status == 201) {
        setSignupsData(response.data['users'])
        setTotalSignUps(response.data['totalCount'])
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getWinners = async (pageNum: number, size: number) => {
    try {
      const response = await api.championship.getWinners(pageNum, size)
      if (response.status == 200 || response.status == 201) {
        setWinnersData(response.data)
        const respHeaders = response.headers
        const xPagination = respHeaders['x-pagination']
        if (xPagination) {
          const xPaginationObj = JSON.parse(xPagination.replace(/'/g, '"'))
          setTotalWinners(xPaginationObj.totalCount)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getRewardedVideos = async (scoreMultiplier: number, pageNum: number, size: number) => {
    try {
      const response = await api.participations.getRewardedVideosPerUser(
        scoreMultiplier,
        pageNum,
        size
      )
      if (response.status == 200 || response.status == 201) {
        setVideosData(response.data)
        const respHeaders = response.headers
        const xPagination = respHeaders['x-pagination']
        if (xPagination) {
          const xPaginationObj = JSON.parse(xPagination.replace(/'/g, '"'))
          setTotalRewardedVideos(xPaginationObj.totalParticipations)
          setRewardedVideosCount(xPaginationObj.totalCount)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getParticipations = async (pageNum: number, size: number) => {
    try {
      const response = await api.participations.getParticipants(pageNum, size)
      if (response.status == 200 || response.status == 201) {
        setParticipationsData(response.data)
        const respHeaders = response.headers
        const xPagination = respHeaders['x-pagination']
        if (xPagination) {
          const xPaginationObj = JSON.parse(xPagination.replace(/'/g, '"'))
          setTotalParticipations(xPaginationObj.totalParticipations)
          setParticipationsCount(xPaginationObj.totalCount)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {/* <h1>Welcome to Mobile Esports Administrator platform!</h1> */}
      <div className='row g-5 g-xl-10 mb-xl-10'>
        <TopBoxes value={totalWinners} text={'Daily Championship Winners'} color='#F7D9E3' />
        <TopBoxes value={totalSignUps} text={'Daily Sign Ups'} color='#CBF0F4' />
        <TopBoxes value={totalRewardedVideos} text={'Daily Rewarded Videos'} color='#CBD4F4' />
        <TopBoxes value={totalParticipations} text={'Daily Participations'} color='#fff2de' />
      </div>
      <div className='row'>
        <div className='col-lg-6 mb-8'>
          <KTCard>
            <ParticipationsTable
              participationsData={participationsData}
              participationsCount={participationsCount}
            />
          </KTCard>
        </div>
        <div className='col-lg-6'>
          <KTCard>
            <RewardedVideosTable
              videosData={videosData}
              rewardedVideosCount={rewardedVideosCount}
            />
          </KTCard>
        </div>
      </div>
      <div className='mb-8'>
        <KTCard>
          <ChampionshipTable winnersData={winnersData} totalWinners={totalWinners} />
        </KTCard>
      </div>
      <div className='mb-8'>
        <KTCard>
          <SignUpTable signupsData={signupsData} totalSignUps={totalSignUps} />
        </KTCard>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
