import axios from 'axios';

export const FILESSERVICE_API_URL = process.env.REACT_APP_FILESSERVICE_URL ? process.env.REACT_APP_FILESSERVICE_URL + '/api/v1' : 'https://test-api-files-service.mesl.eu/api/v1';

const filesServiceClient = axios.create({
    // withCredentials: true,
    // baseURL: 'https://test-api.mesl.eu/api/v1.0',
    baseURL: FILESSERVICE_API_URL
});

export default filesServiceClient;