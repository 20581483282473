/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {GamePayload} from '../../../../../../api/models/response/GamePayload'
import api from '../../../../../../api'
import {FilesPayload} from '../../../../../../api/models/payload/FilesPayload'
import { ChampionshipPayload } from '../../../../../../api/models/response/ChampionshipPayload'
import { UsersPayload } from '../../../../../../api/models/response/UsersPayload'

type Props = {
  item: UsersPayload
}

export default function UserInfoCell({item}: Props) {

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}

 
      <div className='d-flex flex-column'>
        {/* <Link className='text-gray-800 text-hover-primary mb-1' to={`${item?.id}`}>
          {' '} */}
          {item?.username}{' '}
        {/* </Link> */}

        {/* <span>{company.email}</span> */}
      </div>
    </div>
  )
}
