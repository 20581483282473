import axios from 'axios'
import {GameGenericType} from '../models/response/GamePayload'
import coreClient from '../coreClient'

export function get() {
  return coreClient.get<GameGenericType[]>(`/Platforms`, {
    headers: {accept: 'application/json'},
  })
}

export function getPlatformsById(id: string) {
  return coreClient.get<GameGenericType>(`/Platforms/${id}`, {
    headers: {accept: 'application/json'},
  })
}
