/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {TournamentsPayload} from '../../../../../../api/models/response/TournamentsPayload'
import {ChampionshipPayload} from '../../../../../../api/models/response/ChampionshipPayload'
import {formatNumberWithCommas} from '../../../../../../utilitites/formatNumber'

type Props = {
  item: ChampionshipPayload
}

const RpCell: FC<Props> = ({item}) => {
  const formattedNumber = formatNumberWithCommas(item?.rankingPoints)

  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='d-flex flex-column'>
        {/* <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {company.regNumber}
      </a> */}
        <span>
          <img
            src={require('../../../../../../assets/images/RP.png')}
            style={{width: '20px'}}
            alt='rp'
          />{' '}
          {formattedNumber}
        </span>
      </div>
    </div>
  )
}
export {RpCell}
