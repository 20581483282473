import axios from "axios";
import { CountriesPayload } from "../models/response/CountriesPayload";
import client from "../client";
import coreClient from "../coreClient";


export function getCountries(query?: string) {
  const url = query ? `/Countries?${query}` : `/Countries`;
    return coreClient.get<CountriesPayload[]>(url, {
      headers: {accept: 'application/json'},
    })
  }