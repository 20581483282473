import axios from 'axios';

export const IDENTITY_API_URL = process.env.REACT_APP_IDENTITY_URL ? process.env.REACT_APP_IDENTITY_URL + '/api/v1' : 'https://test-api-identity.mesl.eu/api/v1.0';

const identityClient = axios.create({
    // withCredentials: true,
    // baseURL: 'https://test-api.mesl.eu/api/v1.0',
    baseURL: IDENTITY_API_URL
});

export default identityClient;