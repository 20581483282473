/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import { companiesPayload } from '../../../../../../api/models/response/CompaniesPayload'
import { GamePayload } from '../../../../../../api/models/response/GamePayload'
import { ChampionshipPayload } from '../../../../../../api/models/response/ChampionshipPayload'
import { UsersPayload } from '../../../../../../api/models/response/UsersPayload'

type Props = {
  item: UsersPayload
}

const AdGroupCell: FC<Props> = ({item}) =>
{
  
  return (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='d-flex flex-column'>
      {/* <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {company.regNumber}
      </a> */}
      <span> {item?.adGroup}</span>
    </div>
  </div>
)
} 
export {AdGroupCell}
