import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'

import {usersColumns} from './columns/_columns'

import {KTCardBody} from '../../../../../../_metronic/helpers'
import {PaginatedItems} from '../../../../../shared/panination'
import api from '../../../../../api'
import { ChampionshipPayload } from '../../../../../api/models/response/ChampionshipPayload'
import { ENVIRONMENT } from '../../../../../api/client'

type Props = {
  winnersData: ChampionshipPayload[];
  totalWinners: number
}

const ChampionshipTable = ({winnersData, totalWinners} : Props) => {
  // const users = useQueryResponseData()
  // const pagination = useQueryPaginationData()
  // const isLoading = useQueryResponseLoading()
  // const data = useMemo(() => users, [users])
  
  const columns = useMemo(() => usersColumns, [])
  const [data, setWinnerList] = useState<any>([])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const [totalCount, setTotalCount] = useState<number>()
   const [pageNumber, setPageNumber] = useState<number>(0)
   const pageSize = ENVIRONMENT === 'prod' ? 10 : 2;


   useEffect(()=>{
    setWinnerList(winnersData)
    setTotalCount(totalWinners)
   }, [winnersData, totalWinners])

  useEffect(()=>{
    if(pageNumber !==0){
      getWinners(pageNumber, pageSize)
      }
  }, [pageNumber])

  // const [totalWinners, setTotalWinners] = useState<number>(0)
  const getWinners =async (pageNum: number, size: number)=> {
    try {
      const response = await api.championship.getWinners(pageNum, size)
      if (response.status == 200 || response.status == 201) {
        setWinnerList(response.data)
        const respHeaders = response.headers;
        const xPagination = respHeaders['x-pagination'];
           if (xPagination) {
                    const xPaginationObj = JSON.parse(xPagination.replace(/'/g, '"'));
                    if(xPaginationObj.totalCount){
                    setTotalCount(xPaginationObj.totalCount);
                    }
                }

      }
    } catch (error) {
      console.log(error)
    }
  }
//  const {updateState} = useQueryRequest()
  // const updatePage = (page: number | undefined | null) => {
  //   if (!page || isLoading || pagination.page === page) {
  //     return
  //   }
  // }

  const handlePageNumber = (num) => {
    const pageNumber = num
    setPageNumber(pageNumber)
    // updateState({PageNumber: num, PageSize: 10})
  }

  // useEffect(() => {
  //   if (pagination) {
  //     const parsedObject = JSON.parse(pagination)
  //     const tempTotalCount = parsedObject.totalCount
  //     const tempPageNumber = parsedObject.currentPage
  //     setTotalCount(tempTotalCount)
  //     setPageNumber(tempPageNumber)
  //   }
  // }, [pagination])

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
      <div className="border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
			<span className="card-label fw-bold fs-3 mb-1">Daily Championship Winners</span>

			{/* <span className="text-muted mt-1 fw-semibold fs-7">Over 500 members</span> */}
		</h3>

        {/* <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1">
            <a href="#" className="btn btn-sm btn-light btn-active-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends"> 
                <i className="ki-duotone ki-plus fs-2"></i>                New Member
            </a>
        </div> */}
    </div>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<any>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<any>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div> 
      {totalCount && totalCount !== 0 ? (
        <PaginatedItems
          itemsPerPage={pageSize}
          numberOfItems={totalCount}
          handleClick={handlePageNumber}
          pageNumber={pageNumber}
        />
      ) : null
}

      {/* <UsersListPagination /> */}
      {/* {isLoading && <UsersListLoading />} */}
    </KTCardBody>
  )
}

export {ChampionshipTable}
