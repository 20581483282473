import axios from 'axios'
import {GameGenericType} from '../models/response/GamePayload'
import client from '../client'
import coreClient from '../coreClient'

export function get() {
  return coreClient.get<GameGenericType[]>(
    `/ContactInfoTypes?PageNumber=1&PageSize=100`,
    {
      headers: {accept: 'application/json'},
    }
  )
}
