import axios from 'axios'
import client from '../client'
import coreClient from '../coreClient'

export function get() {
  return coreClient.get(`/Championships/1/Winnings?UserId=2d756898-f2bb-4df4-99f6-a77b12cf13e5`, {
    headers: {accept: 'application/json'},
  })
}

export function getWinners(pageNumber?: number, pageSize?: number) {
  const path = pageNumber
    ? `/Championships/Winners?PageNumber=${pageNumber}&PageSize=${pageSize}`
    : `/Championships/Winners`
  return coreClient.get(path, {
    headers: {accept: 'application/json'},
  })
}

export function getChampionship(
  startDate: string,
  endDate: string,
  pageNumber?: number | null,
  searchData?: string
) {
  var path = '/Wallets/RankingPoints/Leaderboard'
  if (startDate !== null || endDate !== null || pageNumber !== null || searchData !== null) {
    path += '?'
  }
  if (startDate != null) {
    path += `StartDate=${startDate}&`
  }
  if (endDate != null) {
    path += `EndDate=${endDate}&`
  }
  if (pageNumber != null) {
    path += `Page=${pageNumber}&`
  }
  if (searchData != null) {
    path += `UserId=${searchData}&`
  }
  return coreClient.get(path)
}
