// @ts-nocheck
import {Column} from 'react-table'
import UserInfoCell from './UserInfoCell'
import {IsNotifiedCell} from './IsNotifiedCell'
import {UserCustomHeader} from './UserCustomHeader'
import {RpCell} from './RpCell'
import {CoinCell} from './CoinCell'
import {PositionCell} from './PositionCell'

import {User} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Position' className='min-w-125px' />,
    id: 'position',
    Cell: ({...props}) => <PositionCell item={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Username' className='min-w-125px' />,
    id: 'nickname',
    Cell: ({...props}) => <UserInfoCell item={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Rps' className='min-w-125px' />
    ),
    id: 'rps',
    Cell: ({...props}) => <RpCell item={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Coins' className='min-w-125px' />
    ),
    id: 'coins',
    Cell: ({...props}) => <CoinCell item={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Notified' className='min-w-125px' />
    ),
    id: 'notified',
    Cell: ({...props}) => <IsNotifiedCell item={props.data[props.row.index]} />,
  },
]

export {usersColumns}
