// @ts-nocheck
import {Column} from 'react-table'
import UserInfoCell from './UserInfoCell'
import {UserCustomHeader} from './UserCustomHeader'
import {VideosCell} from './VideosCell'

import {User} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Username' className='min-w-125px' />,
    id: 'username',
    Cell: ({...props}) => <UserInfoCell item={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Videos Watched' className='min-w-125px' />,
    id: 'videos',
    Cell: ({...props}) => <VideosCell item={props.data[props.row.index]} />,
  },
]

export {usersColumns}
