import axios from 'axios';

export const STREAMING_API_URL = process.env.REACT_APP_STREAMING_URL ? process.env.REACT_APP_STREAMING_URL + '/api/v1' : 'https://test-api-streaming.mesl.eu/api/v1';

const streamingClient = axios.create({
    // withCredentials: true,
    // baseURL: 'https://test-api.mesl.eu/api/v1.0',
    baseURL: STREAMING_API_URL
});

export default streamingClient;