import coreClient from '../coreClient'
import  {AxiosRequestConfig, AxiosResponse} from 'axios'
import {companiesPayload} from '../models/response/CompaniesPayload'
import {companyPayload} from '../models/response/Companypayload'
import client from '../client'

// export function post(
//     payload?: any,
//     // customConfig?: AxiosRequestConfig<any>
// ) {
//     return client.post(`users/authorize`, payload, {
//         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },

//     })
// }
let axiosConfig = {
  headers: {
    accept: 'application/json',
  },
}
// export function post( payload: any, customConfig: AxiosRequestConfig<any>) {
//   return axios.get<any>(`/Companies`, body:payload, axiosConfig)
// }
export function post(payload: any): Promise<AxiosResponse<any>> {
  return client.post<any>(`/Companies`, payload, axiosConfig)
}

export function put(id: number, payload: any): Promise<AxiosResponse<any>> {
  return coreClient.put<any>(
    `/Companies/${id}`,
    payload,
    axiosConfig
  )
}

export function get() {
  return coreClient.get<companiesPayload[]>(
    `/Companies?PageNumber=1&PageSize=100`,
    {
      headers: {accept: 'application/json'},
    }
  )
}

export function getById(id: number) {
  return coreClient.get<companyPayload>(`/Companies/${id}`, {
    headers: {accept: 'application/json'},
  })
}

export function updateContactsById(
  id: number,
  contactId: any,
  payload: any
): Promise<AxiosResponse<any>> {
  return coreClient.put<any>(
    `/Companies/${id}/Contacts/${contactId}`,
    payload,
    axiosConfig
  )
}

export function updateInfoById(
  id: number,
  contactId: any,
  contactInfoId: any,
  payload: any
): Promise<AxiosResponse<any>> {
  return coreClient.put<any>(
    `/Companies/${id}/Contacts/${contactId}/ContactInfos/${contactInfoId}`,
    payload,
    axiosConfig
  )
}

export function updateContactInfosById(
  id: number,
  contactInfoId: any,
  payload: any
): Promise<AxiosResponse<any>> {
  return coreClient.put<any>(
    `/Companies/${id}/ContactInfos/${contactInfoId}`,
    payload,
    axiosConfig
  )
}

export function deleteContactInfoById(id: number, contactInfoId: any): Promise<AxiosResponse<any>> {
  return coreClient.delete<any>(
    `/Companies/${id}/ContactInfos/${contactInfoId}`
  )
}

export function deleteInfoById(
  id: number,
  contactId: any,
  contactInfoId: any
): Promise<AxiosResponse<any>> {
  return coreClient.delete<any>(
    `/Companies/${id}/Contacts/${contactId}/Roles/${contactInfoId}`
  )
}

export function deleteRoleById(
  id: number,
  contactId: any,
  contactInfoId: any
): Promise<AxiosResponse<any>> {
  return coreClient.delete<any>(
    `/Companies/${id}/Contacts/${contactId}/Roles/${contactInfoId}`
  )
}

export function deleteContactsById(id: number, contactId: any): Promise<AxiosResponse<any>> {
  return coreClient.delete<any>(
    `/Companies/${id}/Contacts/${contactId}`
  )
}

export function createInfoById(
  id: number,
  contactId: any,
  contactInfoId: any,
  payload: any
): Promise<AxiosResponse<any>> {
  return coreClient.post<any>(
    `/Companies/${id}/Contacts/${contactId}/ContactInfos`,
    payload,
    axiosConfig
  )
}

export function createRoleById(
  id: number,
  contactId: any,
  contactInfoId: any,
  payload: any
): Promise<AxiosResponse<any>> {
  return coreClient.post<any>(
    `/Companies/${id}/Contacts/${contactId}/Roles`,
    payload,
    axiosConfig
  )
}

export function createContactInfosById(id: number, payload: any): Promise<AxiosResponse<any>> {
  return coreClient.post<any>(
    `/Companies/${id}/ContactInfos`,
    payload,
    axiosConfig
  )
}

export function createContactById(id: number, payload: any): Promise<AxiosResponse<any>> {
  return coreClient.post<any>(
    `/Companies/${id}/Contacts`,
    payload,
    axiosConfig
  )
}
