import {GameGenericType} from '../models/response/GamePayload'
import coreClient from '../coreClient'
import {SystemParametersResponse} from '../models/response/SystemParameters'

// export function get() {
//   return coreClient.get<GameGenericType[]>(`/ScoreOptions`, {
//     headers: {accept: 'application/json'},
//   })
// }

export function getGameSetup(label: string, pageNumber?: number, pageSize?: number) {
  let path = `/${label}`
  if (pageNumber != null || pageSize != null) {
    path += `?`
  }
  if (pageNumber != null) {
    path += `PageNumber=${pageNumber}&`
  }
  if (pageSize != null) {
    path += `PageSize=${pageSize}&`
  }

  return coreClient.get<GameGenericType[]>(path)
}

export function getGameSetupById(label: string, id: number) {
  return coreClient.get<any>(`/${label}/${id}`, {})
  // .then((response: Response<any>) => {const result= response.data;console.log('check', result); return result})
}

export function post(label: string, payload: any) {
  return coreClient.post<SystemParametersResponse>(`/${label}`, payload)
}

export function update(label: string, id: number, payload: any) {
  return coreClient.put<SystemParametersResponse>(`/${label}/${id}`, payload)
}
